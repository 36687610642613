import {Flex, TableColumnsType} from "antd";
import {ITransfer} from "./model/transfers";
import dayjs from "dayjs";

function yearFilters(transfers: ITransfer[] | undefined) {

    if (transfers === undefined) {
        return []
    }

    const allValues = transfers.map(transfer => {
        return transfer.YEAR
    })
    return Array.from(new Set(filterUnique(allValues))).sort()
}

function filterUnique(array: string[]) {
    return Array.from(new Set(array)).map(item => {
        return {
            value: item,
            text: item
        }
    })
}

export function transfersConfig(transfers: ITransfer[] | undefined): TableColumnsType<ITransfer> {
    return [
        {
            title: '№',
            key: "index",
            width: "3%",
            render: (value, record, index) => (
                JSON.stringify(index + 1)
            ),
        },
        {
            title: 'Дата платежа',
            key: 'DATST',
            dataIndex: 'DATST',
            render: (value, record, index) => (
                dayjs(value).format('DD.MM.YYYY')
            )
        },
        {
            title: 'Сумма',
            key: 'SUMM',
            dataIndex: 'SUMM',
            render: (value: string, record, index) => (
                <Flex>
                    <span style={{width: "100%", textAlign: 'right'}}>{`${value !== null ? Number(value.toString().replace(',', '.')).toLocaleString() : ""}`}</span>
                </Flex>
            )
        },
        // {
        //     title: 'Тип платежа',
        //     key: 'TYP',
        //     dataIndex: 'TYP'
        // },
        {
            title: 'Ведомость',
            key: 'DOCNUMB',
            dataIndex: 'DOCNUMB',
            width: "8%",
        },
        {
            title: 'Год',
            key: 'YEAR',
            dataIndex: 'YEAR',
            width: "6%",
            filters: yearFilters(transfers),
            filterSearch: true,
            onFilter: (value, record) => record.YEAR.toString().startsWith(value as string),

        },
        {
            title: 'Месяц',
            key: 'MONTH',
            dataIndex: 'MONTH',
            render: (value, record, index) => (
                dayjs().set('month', value).locale('ru').format('MMMM')
            )
        },
        {
            title: 'Подразделение/Номер темы',
            key: 'KA2',
            dataIndex: 'KA2'
        },
        {
            title: 'Источник финансирования',
            key: 'KA3',
            dataIndex: 'KA3'
        },
        {
            title: 'КЭК',
            key: 'KA4',
            dataIndex: 'KA4'
        }
    ]
}