import {Header} from "antd/es/layout/layout";
import {Avatar, Divider, Flex, Image, Typography} from "antd";

import {UserOutlined} from '@ant-design/icons';
import {TbLogout} from "react-icons/tb";
import {userStore} from "../store/store";
import {getRequest} from "../api/httpRequest";
import {useLocation, useNavigate} from "react-router";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const {Text} = Typography


let LOCATION_MAP = new Map<string, string>([
    ["/statements", "Ведомости"],
    ["/calendar", "Расписание"],
    ["/tasks", "Задачи"],
    ["/plans", "Планы"],
]);

export default function MainHeader() {

    const {user} = userStore()
    const navigate = useNavigate()
    const location = useLocation()
    const {xl} = useBreakpoint();

    const handleLogout = () => {
        getRequest("/esb/v1/auth/logout")
            .then(response => {
                navigate("/login")
            })
    };

    const headerClasses = () => {
        const classes = []
        classes.push("header")

        if (!xl) classes.push("header-xs")
        return classes.join(" ")
    }

    return (
        <Header className={headerClasses()}>
            {xl &&
                <Flex className={"logo"} align={"center"} justify={"center"} gap={"16px"}>
                    <Flex className={"logoImg"}>
                        <Image src={"/content/logo.png"}/>
                    </Flex>
                    <Text className={"logoText"}>Российский университет транспорта</Text>
                </Flex>}
            {xl &&
                <Flex>
                    <div className={"profileText"}>Личный кабинет</div>
                </Flex>}
            <Flex align={"center"} justify={"center"} gap={"16px"}>
                {xl &&
                    <Text className={"logoText"}>{user.Presentation}</Text>
                }
                <Avatar style={{backgroundColor: '#87d068'}} icon={<UserOutlined/>}/>
                <Divider className={"divider"} type={"vertical"}/>
                <TbLogout className={"logout"} onClick={handleLogout}/>
            </Flex>
            {!xl &&
                <Text className={"locationText"}>{LOCATION_MAP.get(location.pathname)}</Text>}
        </Header>
    )
}