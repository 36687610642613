import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter,} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import MainRouter from "./navigation/MainRouter";
import {App} from "antd";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App>
        <BrowserRouter>
            <MainRouter/>
        </BrowserRouter>
    </App>
);

reportWebVitals();
