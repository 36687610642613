import {create} from "zustand";
import {IStatement, IStudentData} from "./model/IStatement";

export interface IStatementState {
    statements: IStatement[],
    setStatements: (newData: IStatement[]) => void,
    addStatementDetails: (details: IStudentData[], statementId: string) => void
}

export const statementsStore = create<IStatementState>()((set) => ({
    statements: [],
    setStatements: (newData) => set((state) => ({statements: newData})),
    addStatementDetails: (details, statementId) => set((state) => (
        {
            statements: state.statements.map(item => {
                if (item.statementId === statementId) {
                    item.details = details
                }
                return item
            })
        })),
}))