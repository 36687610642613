import {Flex, message, Table, TableProps} from "antd";
import React, {useEffect, useState} from "react";
import {getRequest} from "../../../api/httpRequest";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import "./styles/styles.less"
import {IPlan} from "../model/IPlan";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import PlansMobileCard from "./mobile/PlansMobileCard";

export default function PlansList() {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate()
    const [plans, setPlans] = useState<IPlan[]>([])
    const {xl} = useBreakpoint()

    useEffect(() => {
        setIsLoading(true)
        const fetchPlans = async () => {
            try {
                const response = await getRequest("/api/v1/plans")
                setPlans(response.data)
            } catch (error) {
                messageApi.error({
                    type: 'error',
                    content: 'Ошибка загрузки ведомостей',
                })
            }
        }

        fetchPlans()
        setIsLoading(false)
    }, []);

    const tableConfig: TableProps['columns'] = [
        {
            title: 'Учебный год',
            key: "year",
            dataIndex: "year",
            width: "9%"
        },
        {
            title: 'Подразделение',
            key: 'department',
            dataIndex: "department"
        },
        {
            title: 'Должность',
            key: 'position',
            dataIndex: "position"
        },
        {
            title: 'Ставка',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Вид занятости',
            dataIndex: 'employment',
            key: 'employment',
        },
        {
            title: 'Вид начисления',
            dataIndex: 'tarif',
            key: 'tarif',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ]

    return (
        <Flex justify={"center"} align={"flex-start"} style={{width: "100%"}}>
            {contextHolder}
            {xl ?
            <Table
                loading={isLoading}
                className={"plansTable"}
                bordered
                pagination={false}
                columns={tableConfig}
                dataSource={plans}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            navigate("/plans/details", {state: {planId: record.planId}})
                        },
                    };
                }}
            />
                :
                plans.map(plan => (
                    <PlansMobileCard plan={plan}/>
                ))
            }
        </Flex>
    )
}