import "./styles/styles.less"
import {useNavigate} from "react-router";
import {Flex, Layout, Menu, notification} from "antd";
import {Footer} from "antd/es/layout/layout";
import {PiStudentFill} from "react-icons/pi";
import PageContainer from "./PageContainer";
import {MenuInfo} from "rc-menu/lib/interface";
import Sider from "antd/es/layout/Sider";
import {useEffect, useState} from "react";
import MainHeader from "./MainHeader";
import {userStore} from "../store/store";
import {MdMenuOpen, MdOutlineCurrencyRuble} from "react-icons/md";
import {getRequest} from "../api/httpRequest";
import {IoMenu} from "react-icons/io5";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {IRole} from "../store/IUserData";
import {GrDocumentVerified, GrLineChart, GrSchedule, GrTask} from "react-icons/gr";

const iconSize = 20
const menuItems = [
    {
        key: "tasks",
        icon: <GrTask size={iconSize}/>,
        label: "Задачи",
        roles: [
            {
                role: "Teacher"
            },
            {
                role: "Student"
            },
            {
                role: "Other"
            }
        ],
    },
    {
        key: "calendar",
        icon: <GrSchedule size={iconSize}/>,
        label: "Расписание",
        roles: [
            {
                role: "Teacher"
            },
            {
                role: "Student"
            },
            {
                role: "Other"
            }
        ],
    },
    {
        key: "plans",
        icon: <GrLineChart size={iconSize}/>,
        label: "Инд. планы",
        roles: [
            {
                role: "Teacher"
            }
        ],
    },
    {
        key: "education",
        label: "Обучение",
        icon: <PiStudentFill size={iconSize}/>,
        roles: [
            {
                role: "Hide"
            }],
    },
    {
        key: "statements",
        icon: <GrDocumentVerified size={iconSize}/>,
        label: "Ведомости",
        roles: [
            {
                role: "Hide"
            }
        ],
    },
    {
        key: "salary",
        icon: <MdOutlineCurrencyRuble size={iconSize}/>,
        label: "Зарплата",
        roles: [
            {
                role: "Teacher"
            },
            {
                role: "Other"
            }
        ],
    },
]

export default function MainContainer() {

    const navigate = useNavigate()
    const {user, setUser} = userStore();
    const [api, contextHolder] = notification.useNotification();
    const [collapsed, setCollapsed] = useState(false)
    const {xl} = useBreakpoint()

    useEffect(() => {

        const checkTasks = async () => {
            getRequest("/api/v1/tasks/count")
                .then(response => {
                    const count = response.data.count
                    setTimeout(() => {
                        if (count > 0) {
                            api.warning({
                                message: `Имеются открытые задачи: ${count}`,
                                duration: 3
                            });
                        }
                    }, 1000)
                })
        }

        getRequest("/esb/v1/user/")
            .then(response => {
                setUser(response.data)
                let student = false
                let teacher = false
                response.data.Roles.map((role: IRole) => {
                    if (role.Role === "Student") {
                        student = true
                    }
                    if (role.Role === "Teacher") {
                        teacher = true
                    }
                })
                if (teacher) {
                    navigate("/tasks")
                    checkTasks()
                } else if (student) {
                    navigate("/work-in-progress")
                }
            }).catch(error => {
            console.log(error)
        })

    }, []);

    const handleMenuClick = (menuItem: MenuInfo) => {

        if (menuItem.key === "library") {
            window.open('https://library.miit.ru/')
            return
        }

        if (!xl) {
            setCollapsed(!collapsed)
        }
        navigate(`/${menuItem.key}`)
    }

    const filterMenuItems = () => {
        return menuItems.filter(menuItem => {
            const userRoles = user.Roles
            let exists;
            menuItem.roles.forEach(menuItemRole => {
                userRoles.forEach(userRole => {
                    if (userRole.Role === menuItemRole.role) {
                        exists = true
                    }
                })
            })
            return exists
        })
    }

    return (
        <Layout className={"mainLayout"} hasSider={true} style={{paddingTop: "60px"}}>
            {contextHolder}
            <MainHeader/>
            <Flex className={!xl ? "sider-container" : ""}>
                <Sider
                    breakpoint="sm"
                    collapsedWidth="0"
                    collapsed={collapsed}
                    zeroWidthTriggerStyle={{position: "absolute", top: -50, left: 10, border: "none"}}
                    trigger={
                        <Flex className={"sider-trigger"}>
                            {collapsed ?
                                <IoMenu size={"24"}/>
                                :
                                <MdMenuOpen size={"24"}/>
                            }
                        </Flex>
                    }
                    onCollapse={() => setCollapsed(!collapsed)}
                    theme={"light"}
                >
                    <Menu
                        theme="light"
                        mode="inline"
                        items={filterMenuItems()}
                        onClick={(menuItem) => handleMenuClick(menuItem)}
                        defaultSelectedKeys={["tasks"]}
                    />
                </Sider>
            </Flex>
            <Layout>
                <PageContainer/>
                <Footer style={{textAlign: 'center'}}>
                    {`© ${new Date().getFullYear()} «Российский университет транспорта»`}
                </Footer>
            </Layout>
        </Layout>
    )
}