import {Badge, Descriptions, Flex, Typography} from "antd";
import {ITask} from "./model/ITask";
import "./styles/styles.less"
import dayjs from "dayjs";
import {AiFillCheckCircle} from "react-icons/ai";
import {IoIosWarning} from "react-icons/io";
import React from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface ITaskType {
    label: string,
    color: string
}

let TASK_TYPES = new Map<string, ITaskType>([
    ["План", {label: "Учебный план", color: "rgb(48,64,245)"}]
]);

function items(task: ITask) {

    return [
        {
            key: '1',
            label: 'Дата постановки',
            children: <div>{`${dayjs(task.date).format('DD MMMM YYYY')} г.`}</div>,
        },
        {
            key: '2',
            label: 'Срок выполнения',
            children: <div>{`${dayjs(task.deadTime).format('DD MMMM YYYY')} г.`}</div>,
        },
        {
            key: '3',
            label: 'Автор',
            children: <div>{task.author}</div>,
        }
    ]
}



const Task: React.FC<{ task: ITask, onClick: () => void }> = ({task, onClick}) => {

    const {xl} = useBreakpoint()

    function taskClasses(task: ITask) {
        const classes = [
            "task"
        ]

        if (task.completed) {
            classes.push("competed")
        } else if (dayjs(task.deadTime).isBefore(dayjs())) {
            classes.push("expired")
        }

        if (!xl) {
            classes.push("task-xs")
        }

            return classes.join(" ")
    }

    return (
        <Badge.Ribbon className={task.objectType === "Не определено" ? "invisible" : "invisible"}
                      text={TASK_TYPES.get(task.objectType)?.label}
                      color={TASK_TYPES.get(task.objectType)?.color}
        >
            <Flex className={taskClasses(task)}
                  justify={"space-between"}
                  gap={8}
                  vertical
                  align={"flex-start"}
                  onClick={onClick}
            >
                <Flex className={"taskHeader"}
                      gap={8}
                      align={"center"}
                >
                    {task.completed && <AiFillCheckCircle size={24} color={"rgb(90,190,99)"}/>}
                    {!task.completed && dayjs(task.deadTime).isBefore(dayjs()) &&
                        <IoIosWarning size={24} color={"#8B0000FF"}/>}
                    <Typography.Text
                        className={"taskHeaderText"}
                        ellipsis={true}
                        style={{width: 300}}
                    >
                        {task.name}
                    </Typography.Text>
                </Flex>
                <Descriptions colon={true} column={1} items={items(task)}/>
            </Flex>
        </Badge.Ribbon>
    )
}

export default Task