import {Content} from "antd/es/layout/layout";
import {Outlet} from "react-router";
import {Flex} from "antd";

const PageContainer: React.FC = () => {

    return (
        <Content style={{padding: "0 16px", height: "100%"}}>
            <Flex vertical style={{height: "100%"}}>
                <Flex className={"pageContainer"}>
                    <Outlet/>
                </Flex>
            </Flex>
        </Content>
    )
}

export default PageContainer