import {Button, Divider, Flex, Typography} from "antd";
import {FcGraduationCap} from "react-icons/fc";
import {FaMapMarkerAlt} from "react-icons/fa";
import {BsPeopleFill} from "react-icons/bs";
import React from "react";
import {ICalendarType} from "../model/ICalendar";
import dayjs from "dayjs";

let COLOR_MAPPING = new Map<string, string>([
    ["ЛК", "#ea6363"],
    ["З", "#6db241"],
    ["ПР", "#6389ea"],
]);

const CalendarMobileCard: React.FC<{
    timeIndex: number,
    time: any,
    day: any,
    handleCalendarTypeChange: (config: ICalendarType) => void
}> = ({timeIndex, time, day, handleCalendarTypeChange}) => {

    return (

        <Flex className={"lesson-mobile-item"} vertical={true}>
            <Flex gap={8} align={"center"}>
                <Typography.Text>{`${timeIndex + 1} пара: `}</Typography.Text>
                <Typography.Text style={{fontWeight: 2}}>{`${time.time}`}</Typography.Text>
            </Flex>
            <Divider style={{margin: 4}}/>
            {day !== null && day !== undefined &&
                <Flex vertical={true}>
                    {day[timeIndex] ?
                        day[timeIndex].map((lesson: any, index: number) => (
                            <Flex vertical={true} style={{width: "100%"}}>
                                {index > 0 && <Divider style={{margin: 4}}/>}
                                <Flex align={"center"} justify={"space-between"}>
                                    <Typography.Text
                                        className={"lesson-cell-discipline"}>{lesson.discipline}</Typography.Text>
                                    {lesson.showTime &&
                                        <Typography.Text style={{fontSize: "12px", color: "red"}}>
                                            {`Время занятий: ${dayjs(lesson.timeBegin).format('HH:mm')} - ${dayjs(lesson.timeEnd).format('HH:mm')}`}
                                        </Typography.Text>
                                    }
                                </Flex>
                                <Flex gap={8}>
                                    {lesson.teachers.map((teacher: any, index: number) => (
                                        <Button className={"lesson-cell-btn"} type={"text"}
                                                onClick={() => {
                                                    handleCalendarTypeChange({
                                                        teacher: teacher.teacher,
                                                        group: "",
                                                        audirotium: "",
                                                    })
                                                }}
                                                icon={
                                                    <FcGraduationCap/>}>{teacher.teacher}</Button>
                                    ))}

                                </Flex>
                                <Flex gap={8}>
                                    <Button className={"lesson-cell-btn"}
                                            type={"text"}
                                            onClick={() => {
                                                handleCalendarTypeChange({
                                                    teacher: "",
                                                    group: "",
                                                    audirotium: lesson.audirotium,
                                                })
                                            }}
                                            icon={<FaMapMarkerAlt/>}>
                                        {`Аудитория ${lesson.audirotium}`}</Button>
                                </Flex>
                                <Flex gap={8}>
                                    <Button className={"lesson-cell-btn"}
                                            type={"text"}
                                            onClick={() => {
                                                handleCalendarTypeChange({
                                                    teacher: "",
                                                    group: lesson.groups[0].groupName,
                                                    audirotium: "",
                                                })
                                            }}
                                            icon={<BsPeopleFill/>}>{
                                        lesson.groups.map((group: any) => {
                                            let groupText = `${group.groupName}`
                                            if (group.subgroup) {
                                                groupText = `${group.groupName}-${group.subgroup}`
                                            }

                                            return groupText
                                        }).join(", ")}
                                    </Button>
                                </Flex>
                                <div className="badge-mobile"
                                     style={{backgroundColor: COLOR_MAPPING.get(lesson.lessonType)}}>
                                    <span>{`${lesson.lessonType}`}</span>
                                </div>
                            </Flex>)) :
                        <Typography.Text
                            style={{fontWeight: 2}}>{"Нет занятий"}</Typography.Text>}
                </Flex>}
        </Flex>
    )
}

export default CalendarMobileCard