import {Descriptions, Divider, Flex, Typography} from "antd";
import {IStatement} from "../../model/IStatement";
import React from "react";
import dayjs from "dayjs";
import {useNavigate} from "react-router";

function descConfig(statement: IStatement) {

    return [
        {
            key: '1',
            label: 'Дисциплина',
            children: <div>{statement.discipline}</div>,
        },
        {
            key: '2',
            label: 'Группа',
            children: <div>{statement.group}</div>,
        },
        {
            key: '3',
            label: 'Вид контроля',
            children: <div>{statement.statementType}</div>,
        },
        {
            key: '5',
            label: 'Дата окончания',
            children: <span>{`${dayjs(statement.dateClose).format('DD MMMM YYYY')} г.`}</span>,
        }
    ]
}


const StatementMobileCard: React.FC<{ statement: IStatement }> = (statement) => {

    const navigate = useNavigate();

    return (
        <Flex className={"statement-mobile-card"} vertical={true} onClick={() => {
            navigate("/statements/details", {state: {statement: statement.statement}})
        }}>
            <Flex justify={"space-between"}>
                <Typography.Text style={{fontWeight: "500"}}>{`№ ${statement.statement.number}`}</Typography.Text>
                <Typography.Text style={{fontWeight: "500"}}>{` ${statement.statement.lessonType}`}</Typography.Text>
            </Flex>
            <Divider style={{margin: "4px"}}/>
            <Descriptions items={descConfig(statement.statement)}/>
        </Flex>
    )
}

export default StatementMobileCard;