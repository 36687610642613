import {App, Button, Divider, Flex, Form, Input} from "antd";
import {getRequestWithParams} from "../../../api/httpRequest";
import {useState} from "react";
import {useNavigate} from "react-router";

export default function PasswordResetContent() {

    const [isLogging, setIsLogging] = useState(false)
    const {notification} = App.useApp()
    const navigate = useNavigate();
    const [form]  = Form.useForm();
    const [errorForm, setErrorForm]  = useState<boolean>(false);

    async function handleReset(values: any) {
        setIsLogging(true);
        getRequestWithParams("/esb/v1/user/resetPass", {login: values.login}).then(response  => {
            notification.success({
                message: `Ссылка для сброса пороля отправленна на электронную почту`,
                duration: 3
            });
            navigate("/login");
            setIsLogging(false);
        }).catch(error  =>  {
            notification.error({
                message: `Логин не зарегистрирован в системе`,
                duration: 3
            });
            setErrorForm(true);
            setIsLogging(false);
        })
    }

    function resetForm() {
        return <Form
            form={form}
            name="request"
            labelCol={{flex: '70px'}}
            labelAlign="left"
            onFinish={values => handleReset(values)}
            requiredMark={false}>
            <Form.Item
                label="Логин"
                name="login"
                validateStatus={errorForm? "error"  :  ""}
                help={errorForm ? "Логин не зарегистрирован в системе" :""}
                rules={[{required: true, message: 'Логин не может быть пустым!'}]}>
                <Input status={errorForm? "error" : ""}/>
            </Form.Item>
            <Form.Item
                style={{padding: 0, margin: 0}}>
                <Button loading={isLogging} style={{width: "150px", height: "38.5px"}} type="primary"
                        htmlType="submit">
                    Сбросить пароль
                </Button>
            </Form.Item>
        </Form>;
    }

    return (
        <Flex className={"content"} vertical={true}>
            <Flex vertical gap={"16px"}>
                <div className={"lkText"}>Забыли пароль?</div>
                <div className={"continueText"}>Для сброса пароля, пожалуйста, введите логин (электронную почту)</div>
            </Flex>
            <Divider style={{margin: 16}}/>
            {resetForm()}
        </Flex>
    )
}
