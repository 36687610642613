import axios from "axios";
export const BASE_URL = `${process.env.BACK_HOST}`

function axiosInstanceCreate() {


    const axiosInstance = axios.create({
        withCredentials: true,
        timeout: 30000,
        baseURL: `${BASE_URL}`,
    })

    axiosInstance.interceptors.response.use(response => {
        return response;
    }, error => {
        if (window.location.pathname.startsWith('/login')) {
            return Promise.reject(error)
        } else if (error.response.status === 401) {
            if(window.location.pathname !== '/login') {
                window.location.href = '/login'
            }
        }
        return Promise.reject(error);
    })

    return axiosInstance;
}

const axiosInstance = axiosInstanceCreate()

export default axiosInstance
