import {Navigate, Route, Routes, useLocation} from "react-router";
import MainContainer from "./MainPage";
import {ConfigProvider, Empty} from "antd";
import LoginPage from "../pages/login/LoginPage";
import StatementsPage from "../pages/statements/StatementsPage";
import StatementsDetails from "../pages/statements/content/StatementsDetails";
import EducationPage from "../pages/education/EducationPage";
import StatementsList from "../pages/statements/content/StatementsList";
import TaskPage from "../pages/tasks/TaskPage";
import PlansPage from "../pages/plans/PlansPage";
import PlansList from "../pages/plans/content/PlansList";
import PlanDetail from "../pages/plans/content/PlanDetail";
import LoginContent from "../pages/login/content/LoginContent";
import PasswordRequestContent from "../pages/login/content/PasswordRequestContent";
import PasswordResetContent from "../pages/login/content/PasswordResetContent";
import CalendarPage from "../pages/calendar/CalendarPage";
import dayjs from "dayjs";
import locale from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import WorkInProgress from "../pages/stub/WorkInProgres";
import SalaryPage from "../pages/salary/SalaryPage";

export default function MainRouter() {

    const location = useLocation();
    var weekday = require('dayjs/plugin/weekday')
    dayjs.extend(weekday)
    dayjs().locale('ru')

    const customizeRenderEmpty = () => (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{height: 60}}
            description={<span>Данные не найдены</span>}
        />
    );

    return (
        <ConfigProvider theme={{
            components: {
                Descriptions: {
                    itemPaddingBottom: 0
                },
                Table: {
                    cellFontSize: 12,
                },
                Layout: {
                    bodyBg: "rgb(227,234,250)",
                    footerBg: "rgb(227,234,250)",
                }
            }
        }}
                        locale={locale}
                        renderEmpty={customizeRenderEmpty}
        >
            <Routes>
                <Route path="/resetPass" element={<Navigate to={`/login/resetPass${location.search}`}/>}/>
                <Route path="/work-in-progress" element={<WorkInProgress/>}/>
                <Route path="/login" element={<LoginPage/>}>
                    <Route index element={<LoginContent/>}/>
                    <Route path="/login/restore"
                           element={<PasswordRequestContent/>}/>
                    <Route path="/login/resetPass" element={<PasswordResetContent/>}/>
                </Route>
                <Route path="/" element={<MainContainer/>} key={"main"}>
                    <Route key={"statements"} path={"/statements"} element={<StatementsPage/>}>
                        <Route index element={<StatementsList/>}/>
                        <Route key={"statementDetails"} path={"/statements/details"}
                               element={<StatementsDetails/>}/>
                    </Route>
                    <Route key={"tasks"} path={"/tasks"} element={<TaskPage/>}/>
                    <Route key={"plans"} path={"/plans"} element={<PlansPage/>}>
                        <Route index element={<PlansList/>}/>
                        <Route key={"statementDetails"} path={"/plans/details"}
                               element={<PlanDetail/>}/>
                    </Route>

                    <Route key={"education"} path={"/education"} element={<EducationPage/>}/>
                    <Route key={"calendar"} path={"/calendar"} element={<CalendarPage/>}/>
                    <Route key={"salary"} path={"/salary"} element={<SalaryPage/>}/>
                </Route>
            </Routes>
        </ConfigProvider>
    )
}