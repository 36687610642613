import {Button, DatePicker, DatePickerProps, Divider, Flex, Table, TableColumnsType, Typography} from "antd";
import "../styles/styles.less"
import React, {useEffect, useState} from "react";
import {FcGraduationCap} from "react-icons/fc";
import {FaArrowLeft, FaArrowRight, FaMapMarkerAlt} from "react-icons/fa";
import {BsPeopleFill} from "react-icons/bs";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {IoMdArrowRoundBack} from "react-icons/io";
import {getRequestWithParams} from "../../../api/httpRequest";
import {ICalendarType, ILesson, ILessonData} from "../model/ICalendar";

dayjs.extend(weekOfYear);

let COLOR_MAPPING = new Map<string, string>([
    ["ЛК", "#ea6363"],
    ["З", "#6db241"],
    ["ПР", "#6389ea"],
]);

export default function CalendarWebPage() {

    const [calendarData, setCalendarData] = useState<ILesson[]>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [weekStep, setWeekStep] = useState<number>(0);
    const [calendarType, setCalendarType] = useState<ICalendarType>({
        teacher: "",
        group: "",
        audirotium: "",
    });
    const [myCalendar, setMyCalendar] = useState<boolean>(true);

    const weekFormat = 'DD MMMM YYYY';

    const mappedLessons: ILesson[] = [
        {time: "08:30  -  09:50", lessons: []},
        {time: "10:05 - 11:25", lessons: []},
        {time: "11:40 - 13:00", lessons: []},
        {time: "13:45 - 15:05", lessons: []},
        {time: "15:20 - 16:40", lessons: []},
        {time: "16:55 - 18:15", lessons: []},
        {time: "18:30 - 19:50", lessons: []},
        {time: "20:00 - 21:20", lessons: []},
    ];

    const getCalendar = (week: number, calendar: ICalendarType) => {
        setLoading(true)
        try {

            let response;

            if (calendar.group) {
                response = getRequestWithParams(`/calendar/v1/group/${calendar.group}`, {
                    teacher: calendar.teacher,
                    weekStep: `${week}`
                })
            } else if (calendar.audirotium) {
                response = getRequestWithParams(`/calendar/v1/auditorium/${calendar.audirotium}`, {
                    teacher: calendar.teacher,
                    weekStep: `${week}`
                })
            } else {
                response = getRequestWithParams(`/calendar/v1/teacher`, {
                    teacher: calendar.teacher,
                    weekStep: `${week}`
                })
            }

            response?.then(response => {
                if (response.data !== undefined && response.data !== null) {
                    response.data.forEach((dayData: any) => {
                        mappedLessons.forEach((lesson: ILesson, index: number) => {
                            lesson.lessons.push(dayData ? dayData[index] : null)
                        })
                    })
                }

                setCalendarData(mappedLessons)
                setLoading(false)
            })

        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {

        getCalendar(0, calendarType)
    }, []);


    const handleCalendarTypeChange = (value: ICalendarType) => {

        const config = value
        setCalendarType(config)
        setMyCalendar(false)
        getCalendar(weekStep, config)
    }

    const lessonCell = (value: ILessonData[]) => {

        if (value) {
            return (
                <Flex vertical={true}>
                    {value.map((lesson, index) => (
                        <Flex className={"lesson-cell"} vertical={true} gap={4}>
                            {index > 0 && <Divider style={{margin: 4}}/>}
                            <div className="badge" style={{backgroundColor: COLOR_MAPPING.get(lesson.lessonType)}}>
                                <span>{lesson.lessonType}</span>
                            </div>
                            <Flex>
                                <div className={"lesson-cell-discipline"}>{lesson.discipline}</div>
                            </Flex>
                            {lesson.showTime &&
                                <Flex>
                                    <div style={{fontSize: "12px", color: "red"}}>{`Время занятий: ${dayjs(lesson.timeBegin).format('HH:mm')} - ${dayjs(lesson.timeEnd).format('HH:mm')}`}</div>
                                </Flex>
                            }
                            <Flex>
                                <div className={"lesson-cell-discipline"}>{lesson.showTime}</div>
                            </Flex>
                            {lesson.teachers.map(teacher => (
                                <Flex gap={8}>
                                    <Button className={"lesson-cell-btn"} type={"text"} onClick={() => {
                                        handleCalendarTypeChange({
                                            teacher: teacher.teacher,
                                            group: "",
                                            audirotium: "",
                                        })
                                    }}
                                            icon={<FcGraduationCap/>}>{teacher.teacher}</Button>
                                </Flex>
                            ))}

                            <Flex gap={8}>
                                <Button className={"lesson-cell-btn"}
                                        type={"text"}
                                        onClick={() => {
                                            handleCalendarTypeChange({
                                                teacher: "",
                                                group: "",
                                                audirotium: lesson.audirotium,
                                            })
                                        }}
                                        icon={<FaMapMarkerAlt/>}>
                                    {`Аудитория ${lesson.audirotium}`}</Button>
                            </Flex>
                            <Flex gap={8}>
                                <Button className={"lesson-cell-btn"}
                                        type={"text"}
                                        onClick={() => {
                                            handleCalendarTypeChange({
                                                teacher: "",
                                                group: lesson.groups[0].groupName,
                                                audirotium: "",
                                            })
                                        }}
                                        icon={<BsPeopleFill/>}>{
                                    lesson.groups.map(group => {
                                        let groupText = `${group.groupName}`
                                        if (group.subgroup) {
                                            groupText = `${group.groupName}-${group.subgroup}`
                                        }

                                        return groupText
                                    }).join(", ")}
                                </Button>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>

            )
        } else {
            return <div className={"lesson-cell"}></div>
        }
    }

    const getHeaderDay = (offset: number) => {
        return dayjs().add(weekStep, "week").endOf("week").add(-offset, "day").format("DD MMMM")
    }

    const titleComponent = (day: string, offset: number) => {
        return (
            <Flex vertical={true}>
                <div>{day}</div>
                <div style={{fontWeight: "1"}}>{getHeaderDay(offset)}</div>
            </Flex>
        )
    }

    const tableConfig: TableColumnsType<ILesson> = [
        {
            title: '',
            width: "7%",
            dataIndex: "time",
            render: (value, record, index) => (
                <Flex style={{width: "100%"}} vertical={true}>
                    <div>{`${index + 1} пара`}</div>
                    <div style={{fontWeight: "1"}}>{value}</div>
                </Flex>
            ),
        },
        {
            title: titleComponent("Понедельник", 6),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[0])
        },
        {
            title: titleComponent("Вторник", 5),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[1])
        },
        {
            title: titleComponent("Среда", 4),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[2])
        },
        {
            title: titleComponent("Четверг", 3),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[3])
        },
        {
            title: titleComponent("Пятница", 2),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[4])
        },
        {
            title: titleComponent("Суббота", 1),
            dataIndex: "lessons",
            render: (value) => lessonCell(value[5])
        }
    ]

    const handleWeekStep = (number: number) => {
        const newWeekStep = weekStep + number
        setWeekStep(newWeekStep)
        getCalendar(newWeekStep, calendarType)
    };

    const onWeekSelect: DatePickerProps['onChange'] = (date, dateString) => {
        if (date !== null) {
            const newWeekStep = -(dayjs().week() - dayjs(date).week())
            setWeekStep(newWeekStep)
            getCalendar(newWeekStep, calendarType)
        } else {
            setWeekStep(0)
            getCalendar(0, calendarType)
        }
    };

    const customWeekStartEndFormat: DatePickerProps['format'] = (value) =>
        `${dayjs(value).startOf('week').format(weekFormat)} - ${dayjs(value)
            .endOf('week')
            .format(weekFormat)}`;

    const headerText = () => {
        if (calendarType.group) {
            return `Расписание по группе ${calendarType.group}`
        } else if (calendarType.audirotium) {
            return `Расписание по аудитории ${calendarType.audirotium}`
        } else if (calendarType.teacher) {
            return `Расписание преподавателя ${calendarType.teacher}`
        } else {
            return "Моё расписание"
        }
    };

    return (
        <Flex vertical={true} style={{width: "100%", height: "100%", paddingTop: 16}} gap={16}>
            <Flex align={"center"}>
                {!myCalendar &&
                    <Button
                        onClick={() => {
                            const config =
                                {
                                    teacher: "",
                                    group: "",
                                    audirotium: "",
                                }

                            setCalendarType(config)
                            setMyCalendar(true)
                            getCalendar(weekStep, config)
                        }}
                        icon={<IoMdArrowRoundBack/>}
                        type={"text"}>К моему расписанию
                    </Button>}
                <Typography.Text style={{fontSize: "18px"}}>{headerText()}</Typography.Text>
            </Flex>
            <Flex gap={8}>
                <Button
                    icon={<FaArrowLeft size={10}/>}
                    onClick={() => handleWeekStep(-1)}
                >
                    {"Предыдущая неделя"}
                </Button>
                <Button icon={<FaArrowRight size={10}/>}
                        iconPosition={"end"}
                        onClick={() => handleWeekStep(1)}
                >
                    {"Следующая неделя"}
                </Button>

                <DatePicker onChange={onWeekSelect}
                            format={customWeekStartEndFormat}
                            picker="week"
                            placeholder={"Выбрать"}/>
            </Flex>
            <Table
                bordered
                loading={isLoading}
                size={"small"}
                pagination={false}
                columns={tableConfig}
                dataSource={calendarData}/>
        </Flex>
    )

}