import {
    Button,
    DatePicker,
    Descriptions,
    Flex,
    notification,
    Popover,
    Select,
    Table,
    TableColumnsType,
    Typography
} from "antd";
import React, {useEffect, useState} from "react";
import {IStudentData} from "../model/IStatementDetails";
import {useLocation, useNavigate} from "react-router";
import {getRequestWithParams, putRequestWithParamsAndBody} from "../../../api/httpRequest";
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import './styles/styles.less'
import {IStatement} from "../model/IStatement";
import {IoMdArrowRoundBack} from "react-icons/io";
import {BsCheckLg} from "react-icons/bs";
import {FaFilePdf} from "react-icons/fa6";
import {AiFillEdit} from "react-icons/ai";

import ru from 'antd/es/date-picker/locale/ru_RU';
import {IMarkType} from "../model/IMarkType";
import {desc} from "./config/detailsConfig";

const {Text} = Typography

interface IErrorModal {
    dateError: boolean,
    markError: boolean
}

export default function StatementsDetails() {

    const location = useLocation()
    const [markTypes, setMarkTypes] = useState<IMarkType[]>()
    const [currentStatementDetails, setCurrentStatementDetails] = useState()
    const currentStatement: IStatement = location.state.statement;
    const navigate = useNavigate()
    const [editingStudent, setEditingStudent] = useState<IStudentData>()
    const [errorModal, setErrorModal] = useState<IErrorModal>({dateError: false, markError: false})
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmLoading, setIsConfirmLoading] = useState(false)
    const [openPopover, setOpenPopover] = useState(false)
    const [currentMarkDate, setCurrentMarkDate] = useState(dayjs())

    const fetchStatementDetails = async () => {
        setIsLoading(true)
        try {
            const response = await getRequestWithParams("/api/v1/statement", {
                statementId: `${location.state.statement.statementId}`
            })
            setCurrentStatementDetails(response.data.map((student: IStudentData) => {
                return {
                    ...student,
                    markDate: student.markDate === "0001-01-01T00:00:00" ? null : student.markDate,
                    expelledDate: student.expelledDate === "0001-01-01T00:00:00" ? null : student.expelledDate
                }
            }))
        } catch (e) {
            console.error(JSON.stringify(e))
        }
        setIsLoading(false)
    }

    useEffect(() => {

        const fetchMarkTypes = async () => {
            try {
                const response = await getRequestWithParams("/api/v1/catalog/markType", {
                    markTypeId: `${location.state.statement.markTypeId}`
                })
                setMarkTypes(response.data)
            } catch (e) {
                console.error(JSON.stringify(e))
            }
        }

        fetchStatementDetails()
        fetchMarkTypes()
    }, []);

    const handleEdit = (student: IStudentData) => {
        setOpenPopover(!openPopover)
        setEditingStudent({
            ...student,
            markDate: student.markDate ? student.markDate : currentMarkDate.toISOString()
        })
    }

    const calculateDefault = () => {
        if (editingStudent?.markDate) {
            console.log(`editingStudent?.markDate ${editingStudent?.markDate}`)
            return dayjs(editingStudent?.markDate)
        } else {
            console.log(`currentMarkDate: ${currentMarkDate}`)
            return currentMarkDate;
        }
    };
    const tableConfig: TableColumnsType<IStudentData> = [
        {
            title: '№',
            width: "20px",
            render: (value, record, index) => (
                JSON.stringify(index + 1)
            ),
        },
        {
            title: "ФИО Студента",
            key: "student",
            width: "60%",
            render: value => (
                <Flex vertical>
                    <div>{value.student}</div>
                    {value.expelledDate && <div
                        style={{color: "red"}}>{`Дата выбытия: ${dayjs(value.expelledDate).format('DD MMMM YYYY')} г.`}</div>}
                </Flex>
            )
        },
        {
            title: "Дата сдачи", //TODO ограничить ввод. Нельзя будущей, нельзя позже даты закрытия. Отчисленному нельзя ставить дату позже даты отчисления включая ее
            dataIndex: "markDate",
            key: "markDate",
            render: value => {

                if (value === null) {
                    return
                }

                return (
                    <span>{`${dayjs(value).format('DD MMMM YYYY')} г.`}</span>
                )
            }
        },
        {
            title: "Оценка",
            dataIndex: "mark",
            key: "mark",
            render: (value, record) => (
                <Flex gap={"8px"}>
                    <span>{`${value.markName}`}</span>
                </Flex>
            )
        },
        {
            title: "",
            key: "actions",
            width: "20px",
            render: (value, record) => (

                <Popover
                    open={openPopover && record.student === editingStudent?.student}
                    content={
                        <Flex vertical gap={16}>
                            <Flex align={"center"} gap={"16px"}>
                                    <Text>Дата сдачи</Text>
                                    <DatePicker
                                        locale={ru}
                                        allowClear={false}
                                        status={errorModal.dateError ? "error" : ""}
                                        format={"DD.MM.YYYY"}
                                        onChange={(date) => handleMarkDateChange(date)}
                                        defaultValue={calculateDefault()}
                                        maxDate={dayjs(currentStatement.dateClose)}
                                        minDate={dayjs(currentStatement.dateOpen)}
                                    />
                                    <Text>Оценка</Text>
                                    <Select
                                        popupMatchSelectWidth
                                        status={errorModal.markError ? "error" : ""}
                                        style={{minWidth: "100px"}}
                                        onChange={(value) => {
                                            handleChangeMark(value)
                                        }}
                                        options={markTypes?.map(mark => {
                                            return {
                                                value: `${mark.markName}`,
                                                label: `${mark.markName}`
                                            }
                                        })}
                                        defaultValue={editingStudent?.mark.markName}
                                    />
                            </Flex>

                            <Flex gap={8} justify={"flex-end"}>
                                <Button
                                    type={"primary"}
                                    onClick={handleOkModal}
                                    loading={isConfirmLoading}
                                >
                                    Подписать
                                </Button>
                                <Button
                                    onClick={() => {
                                        setEditingStudent(undefined)
                                        setOpenPopover(false)
                                    }}
                                    loading={isConfirmLoading}
                                >
                                    Закрыть
                                </Button>
                            </Flex>
                        </Flex>
                    }
                    title={`${record.student}`}
                    trigger="click"
                    placement={"leftTop"}
                >
                    <Button
                        disabled={currentStatement.justApprove}
                        size={"middle"}
                        onClick={() => handleEdit(record)}
                        icon={<AiFillEdit/>}
                    />
                </Popover>

            )
        }
    ]

    const handleOkModal = () => {

        if (editingStudent === undefined) {
            throw new TypeError()
        }

        let request: IStudentData = editingStudent

        if (editingStudent?.mark === null || editingStudent?.mark.markId === "00000000-0000-0000-0000-000000000000") {
            setErrorModal({...errorModal, markError: true})
            return
        }

        setIsConfirmLoading(true)
        putRequestWithParamsAndBody(
            "/api/v1/saveMark",
            {
                statementId: currentStatement.statementId
            },
            request
        ).then(response => {
            api.success({
                message: 'Оценка успешно отправлена',
                duration: 2
            });
            setCurrentMarkDate(dayjs(editingStudent.markDate))
            setOpenPopover(false)
            fetchStatementDetails()
        }).catch(error => {
            console.error(error)
            api.error({
                message: 'Ошибка отправки оценки',
                duration: 2
            });
        })

        setIsConfirmLoading(false)
    }

    const handleMarkDateChange = (newDate: dayjs.Dayjs) => {
        if (editingStudent === undefined) {
            throw new TypeError()
        }

        setEditingStudent({...editingStudent, markDate: newDate.toISOString()})
    }

    const handleChangeMark = (value: string) => {

        if (editingStudent === undefined) {
            throw new TypeError()
        }

        const newMark = markTypes?.find(type => type.markName === value)

        if (newMark === undefined) {
            throw new TypeError()
        }

        setErrorModal({...errorModal, markError: false})
        setEditingStudent(
            {
                ...editingStudent,
                mark: {
                    markId: newMark.merkId,
                    markName: newMark.markName,
                    markWeight: newMark.markWeight
                }
            })
    }

    return (
        <Flex vertical style={{width: "100%"}} gap={"16px"}>
            {contextHolder}
            <Flex className={"actions"} justify={"space-between"}>
                <Button
                    onClick={() => navigate("/statements")}
                    icon={<IoMdArrowRoundBack/>}
                    type={"text"}>К списку ведомостей
                </Button>
                <Flex gap={"16px"}>
                    {currentStatement.justApprove &&
                        <Button
                            className={"actionButton"}
                            onClick={() => alert("Signed")}
                            icon={<BsCheckLg/>}
                            type={"primary"}>Подписать
                        </Button>
                    }
                    <Button
                        className={"actionButton"}
                        onClick={() => alert("Printed")}
                        icon={<FaFilePdf/>}
                        type={"default"}>Печать
                    </Button>
                </Flex>
            </Flex>
            <Descriptions
                size={"small"}
                className={"descriptions"}
                title={`Данные ведомости №${location.state.statement.number}`}
                items={desc(currentStatement)}/>
            <Table
                loading={isLoading}
                size={"small"}
                className={"statementsTable"}
                bordered
                pagination={false}
                columns={tableConfig}
                dataSource={currentStatementDetails}/>
        </Flex>
    )
}
