import dayjs from "dayjs";
import React from "react";
import {IStatement} from "../../model/IStatement";

export function desc(currentStatement: IStatement) {
    return [

        {
            key: '6',
            label: 'Группа',
            children: <div>{currentStatement.group}</div>,
        },
        {
            key: '5',
            label: 'Тип ведомости',
            children: <div>{currentStatement.statementType}</div>,
        },
        {
            key: '7',
            label: 'Дата начала действия',
            children: <div>{`${dayjs(currentStatement.dateOpen).format('DD MMMM YYYY')} г.`}</div>,
        },
        {
            key: '1',
            label: 'Дисциплина',
            children: <div>{currentStatement.discipline}</div>,
        },
        {
            key: '2',
            label: 'Вид контроля',
            children: <div>{currentStatement.lessonType}</div>,
        },
        {
            key: '8',
            label: 'Дата окончания действия',
            children: <div>{`${dayjs(currentStatement.dateClose).format('DD MMMM YYYY')} г.`}</div>,
        }
    ]
}
