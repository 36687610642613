import {Descriptions, Divider, Flex, Table, TableProps} from "antd";
import React, {useState} from "react";
import {IPlanDetails} from "../../model/IPlanDetails";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const PlansLessonsComponent: React.FC<{ details: IPlanDetails | undefined }> = ({details}) => {

    const [isLoading, setIsLoading] = useState(false)
    const {xl} = useBreakpoint()

    function lessonsDesc(lesson: any) {
        return [
            {
                label: 'Дисциплина',
                key: 'discipline',
                children: <div>{lesson.discipline}</div>
            },
            {
                key: 'employee',
                label: 'Предоставление плана',
                children: <div>{lesson.employee}</div>,
            },
            {
                label: 'Период контроля',
                key: 'period',
                children: <div>{lesson.period}</div>
            },
            {
                label: 'Нагрузка',
                key: 'lessonType',
                children: <div>{lesson.lessonType}</div>
            },
            {
                label: 'Контингент нагрузки',
                key: 'groups',
                children: <div>{lesson.groups}</div>
            },
            {
                label: 'Часы',
                key: 'hours',
                children: <div>{lesson.hours}</div>
            },
            {
                label: 'Количество обучающихся',
                key: 'numberOfStudents',
                children: <div>{lesson.numberOfStudents}</div>
            }
        ]
    }


    const lessonsTable: TableProps['columns'] = [
        {
            title: 'Дисциплина',
            key: 'discipline',
            dataIndex: "discipline",
        },
        {
            title: 'Период контроля',
            key: 'period',
            dataIndex: "period"
        },
        {
            title: 'Нагрузка',
            key: 'lessonType',
            dataIndex: "lessonType"
        },
        {
            title: 'Контингент нагрузки',
            key: 'groups',
            dataIndex: "groups"
        },
        {
            title: 'Часы',
            key: 'hours',
            dataIndex: "hours"
        },
        {
            title: 'Количество обучающихся',
            key: 'numberOfStudents',
            dataIndex: "numberOfStudents"
        }
    ]

    return (
        <Flex>
            <Flex>
                {xl ?
                    <Table
                        loading={isLoading}
                        className={"plansTable"}
                        bordered={false}
                        pagination={false}
                        columns={lessonsTable}
                        dataSource={details?.lessons}/>
                    :
                    <Flex vertical={true}>
                        {details?.lessons.map((lesson, index) => (
                            <Flex vertical={true}>
                                {index > 0 && <Divider/>}
                                <Descriptions items={lessonsDesc(lesson)}/>
                            </Flex>
                        ))}
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

export default PlansLessonsComponent