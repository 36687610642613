import {Flex} from "antd";
import {Outlet} from "react-router";
import React from "react";

export default function PlansPage() {

    return (
        <Flex style={{width: "100%", height: "100%", paddingTop: "16px"}}>
            <Outlet/>
        </Flex>
    )
}