import {Button, Col, Descriptions, Divider, Flex, Form, Input, message, Modal, Row, Space, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {ITask} from "./model/ITask";
import axiosInstance from "../../api/axiosInstance";
import Task from "./Task";
import dayjs from "dayjs";
import {putRequestWithParamsAndBody} from "../../api/httpRequest";
import {useLocation, useNavigate} from "react-router";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export default function TaskPage() {

    const [tasks, setTasks] = useState<ITask[]>([])
    const [showCompleted, setShowCompleted] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [openedTask, setOpenedTask] = useState<ITask>()
    const [openModal, setOpenModal] = useState(false)
    const [form] = Form.useForm();
    const {xl} = useBreakpoint();
    const [formError, setFormError] = useState(false)
    const [submittable, setSubmittable] = useState<boolean>(false);
    const [closingTask, setClosingTask] = useState<boolean>(false);
    const navigate = useNavigate()
    const location = useLocation()

    const fetchTasks = async () => {
        await axiosInstance.get("/api/v1/tasks")
            .then(response => {

                const newTasks = response.data.map((item: ITask) => {
                    return {
                        ...item,
                        date: item.date === "0001-01-01T00:00:00" ? null : item.date,
                        deadTime: item.deadTime === "0001-01-01T00:00:00" ? null : item.deadTime,
                        completedTime: item.completedTime === "0001-01-01T00:00:00" ? null : item.completedTime,
                    }
                })

                setTasks(newTasks)
                if (location.state?.taskId) {
                    setOpenedTask(newTasks.find((item: { taskId: any; }) => item.taskId === location.state.taskId))
                    setOpenModal(true)
                }
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Ошибка загрузки задач',
                });
            })
    }

    useEffect(() => {
        fetchTasks()
    }, []);

    const onFilterClick = () => {
        setShowCompleted(!showCompleted)
    }

    const openObject = () => {
        switch (openedTask?.objectType) {
            case "План": {
                navigate("/plans/details", {state: {planId: openedTask?.objectId, taskId: openedTask?.taskId}})
            }
        }
    }

    const items = () => {

        dayjs.locale('ru');
        return [
            {
                key: '1',
                label: 'Дата постановки',
                children: <div>{`${dayjs(openedTask?.date).format('DD MMMM YYYY')} г.`}</div>,
            },
            {
                key: '2',
                label: 'Срок выполнения',
                children: <div>{`${dayjs(openedTask?.deadTime).format('DD MMMM YYYY')} г.`}</div>,
            },
            {
                key: '3',
                label: 'Автор',
                children: <div>{openedTask?.author}</div>,
            }
        ]
    }

    const closeTask = (approve: boolean) => {
        setClosingTask(true)
        const comment = form.getFieldValue("comment");
        if (!comment) {
            setFormError(true)
        }

        putRequestWithParamsAndBody("/api/v1/tasks", {taskId: openedTask?.taskId}, {
            result: comment,
            approved: approve
        }).then(response => {
            fetchTasks()
            setOpenModal(false)
            setClosingTask(false)
        }).catch(error => {
            messageApi.open({
                type: 'error',
                content: 'Ошибка отправки результата по задаче',
            });
        })

    };

    return (
        <Flex vertical style={{width: "100%", height: "100%", paddingTop: "16px"}} gap={8}>
            <Button style={xl ? {width: "300px"} : {width: "100%"}}
                    onClick={onFilterClick}>{!showCompleted ? "Показать выполненные" : "Скрыть выполненные"}</Button>
            {contextHolder}
            <Row>
                {tasks.map((task) => {
                    if (!showCompleted && task.completed) {
                        return <></>
                    }

                    return (
                        <Col style={{padding: "4px"}}>
                            <Task task={task} onClick={() => {
                                setOpenedTask(task)
                                setOpenModal(true)
                                setFormError(false)
                                form.resetFields()
                                setSubmittable(false)
                            }
                            }/>
                        </Col>
                    );
                })}
            </Row>
            <Modal
                centered
                width={1000}
                destroyOnClose
                open={openModal}
                title={`${openedTask?.name}`}
                onCancel={() => setOpenModal(false)}
                footer={null}
            >
                <Flex justify={"center"} vertical={true}>
                    <Descriptions bordered={false} items={items()}/>
                    <Divider style={{margin: 8}}/>
                    <Space direction={"vertical"} size={"large"}>
                        <Flex vertical={true} gap={8}>
                            <Typography.Text className={"descText"}>Описание задачи:</Typography.Text>
                            <Typography.Text>{openedTask?.description}</Typography.Text>
                            {openedTask?.objectType !== "Не определено" &&
                                <Button
                                    style={{width: 100}}
                                    onClick={openObject}
                                >Перейти
                                </Button>}
                        </Flex>
                        {openedTask?.story &&
                            <Flex vertical={true}>
                                <Divider style={{margin: 8}}/>
                                <Typography.Text className={"descText"}>История задачи:</Typography.Text>
                                <Typography.Text
                                    style={{whiteSpace: "pre-line"}}>{openedTask.story.substring(0, openedTask.story.length - 2)}</Typography.Text>
                            </Flex>}
                        {!openedTask?.completed &&
                            <Form
                                name="validateOnly"
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    style={{marginBottom: 16}}
                                    name="comment"
                                    label={"Добавить комментарий:"}
                                    validateStatus={formError ? "error" : "success"}
                                    rules={[{required: openedTask?.returnable}]}
                                >
                                    <Input placeholder="Комментарий" onChange={() => setSubmittable(true)}/>
                                </Form.Item>
                                <Form.Item style={{margin: 0}}>
                                    <Space>
                                        <Button disabled={openedTask?.returnable ? !submittable : false} type="primary" loading={closingTask}
                                                onClick={() => closeTask(true)}>{openedTask?.objectType === "План" ? "Подписать" : "Выполнить"}</Button>
                                        {openedTask?.returnable &&
                                            <Button disabled={!submittable} onClick={() => closeTask(false)}>Вернуть
                                                исполнителю</Button>}
                                    </Space>
                                </Form.Item>
                            </Form>}
                    </Space>
                </Flex>
            </Modal>

        </Flex>
    )
}