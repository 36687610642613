import {ConfigProvider, Flex, Tabs} from "antd";
import React from "react";
import "./styles/styles.less"
import Accruals from "./tabs/accruals/Accruals";
import Transfers from "./tabs/transfers/Transfers";


export default function SalaryPage() {

    const tabsConfig = [
        {
            label: `Начисления`,
            key: "1",
            children:
                <Accruals/>,
        },
        {
            label: `Перечисления`,
            key: "2",
            children:
                <Transfers/>,
        }
    ]

    return (
        <Flex className={"salary-page"} vertical={true}>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBg: "#f3f4f8",
                            cellPaddingBlockSM: 2,
                            stickyScrollBarBg: "gray",
                            stickyScrollBarBorderRadius: 200,
                            borderColor: "#a6a6a6",
                            rowHoverBg: "lightgray",
                            cellFontSizeSM: 14,
                            headerBorderRadius: 0,
                        },
                    },
                }}>
                <Tabs style={{width: "100%"}}
                      defaultActiveKey="1"
                      items={tabsConfig}
                />

            </ConfigProvider>
        </Flex>
    )
}