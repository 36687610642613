import {Descriptions, Divider, Flex, Typography} from "antd";
import {IPlan} from "../../model/IPlan";
import "./styles/styles.less"
import React from "react";
import {useNavigate} from "react-router";

function descConfig(plan: IPlan) {

    return [
        {
            key: '1',
            label: 'Учебный год',
            children: <div>{plan.year}</div>,
        },
        {
            key: '2',
            label: 'Подразделение',
            children: <div>{plan.department}</div>,
        },
        {
            key: '4',
            label: 'Вид занятости',
            children: <div>{plan.employment}</div>,
        },
        {
            key: '5',
            label: 'Вид начисления',
            children: <div>{plan.tarif}</div>,
        },
        {
            key: '6',
            label: 'Статус',
            children: <div>{plan.status}</div>,
        },
        {
            key: '3',
            label: 'Ставка',
            children: <div>{plan.rate}</div>,
        }
    ]
}

const PlansMobileCard: React.FC<{ plan: IPlan }> = ({plan}) => {

    const navigate = useNavigate()

    return (
        <Flex
            className={"plans-mobile-card"}
            vertical={true}
            onClick={() => {
                navigate("/plans/details", {state: {planId: plan.planId}})
            }}
        >
            <Typography.Text style={{fontWeight: 500}}>{plan.position}</Typography.Text>
            <div className="plans-mobile-badge">
                <span>{`${plan.status}`}</span>
            </div>
            <Divider style={{margin: 8}}/>
            <Descriptions items={descConfig(plan)}/>
        </Flex>
    )
}

export default PlansMobileCard