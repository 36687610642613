import {Flex, TableColumnsType} from "antd";
import {ISalaryData, ISalaryHeader} from "../../model/ISalary";
import dayjs from "dayjs";

export function codeFilters(headers: ISalaryHeader[]) {
    const allValues = headers.map(header => {
        return header.POST_CODE
    })
    return Array.from(new Set(filterUnique(allValues)))
}

export function depFilters(headers: ISalaryHeader[]) {
    const allValues = headers.map(header => {
        return header.INS_DEP
    })
    return Array.from(new Set(filterUnique(allValues)))
}

export function yearFilters(headers: ISalaryHeader[]) {
    const allValues = headers.map(header => {
        return dayjs(header.EMP_CALC).year().toString()
    })
    return Array.from(new Set(filterUnique(allValues)))
}
export function monthFilters(headers: ISalaryHeader[]) {
    const allValues = headers.map(header => {
        return dayjs(header.EMP_CALC).format('MMMM').toString()
    })
    return Array.from(new Set(filterUnique(allValues)))
}

function filterUnique(array: string[]) {
    return Array.from(new Set(array)).map(item => {
        return {
            value: item,
            text: item
        }
    })
}

export function headersTableConfig(headers: ISalaryHeader[]): TableColumnsType<ISalaryHeader> {
    return [

        {
            title: 'Должность',
            key: 'POST_CODE',
            width: "15%",
            dataIndex: 'POST_CODE',
            filters: codeFilters(headers),
            filterSearch: true,
            onFilter: (value, record) => record.POST_CODE.startsWith(value as string),
        },
        {
            title: 'Подразделение',
            key: 'INS_DEP',
            dataIndex: 'INS_DEP',
            filters: depFilters(headers),
            filterSearch: true,
            onFilter: (value, record) => record.INS_DEP.startsWith(value as string),
        },
        {
            title: 'Год',
            key: 'EMP_CALC_YEAR',
            width: "5%",
            dataIndex: 'EMP_CALC',
            filters: yearFilters(headers),
            filterSearch: true,
            onFilter: (value, record) => dayjs(record.EMP_CALC).year().toString() === value,
            render: value => (
                <span>{dayjs(value).year()}</span>
            )
        },
        {
            title: 'Месяц',
            key: 'EMP_CALC_MONTH',
            dataIndex: 'EMP_CALC',
            width: "7%",
            filters: monthFilters(headers),
            filterSearch: true,
            onFilter: (value, record) => dayjs(record.EMP_CALC).format('MMMM').toString() === value,
            render: value => (
                <span>{dayjs(value).locale('ru').format('MMMM')}</span>
            )
        },
        {
            title: 'Начислено',
            key: 'PAYS_SUMM',
            dataIndex: 'PAYS_SUMM',
            width: "10%",
            render: (value: string, record, index) => (
                <Flex>
                    <span style={{width: "100%", textAlign: 'right'}}>{`${value !== null ? Number(value.toString().replace(',', '.')).toLocaleString() : ""}`}</span>
                </Flex>
            )
        }
    ]
}

export const tableConfig: TableColumnsType<ISalaryData> = [
    {
        title: '№',
        width: "3%",
        render: (value, record, index) => (
            JSON.stringify(index + 1)
        ),
    },
    {
        title: 'Наименование',
        key: "PAYS_NAME",
        dataIndex: "PAYS_NAME"
    },
    {
        title: 'Ведомость',
        key: "PAYS_SHT",
        dataIndex: "PAYS_SHT"
    },
    {
        title: 'Месяц перерасчёта',
        key: "",
        dataIndex: ""
    },
    {
        title: 'Период',
        key: "PAYS_BEGEND",
        dataIndex: "PAYS_BEGEND"
    },
    {
        title: 'Рабочние дни',
        key: "PAYS_WRK",
        dataIndex: "PAYS_WRK"
    },
    {
        title: 'Процент',
        key: "PAYS_PRC",
        dataIndex: "PAYS_PRC"
    },
    {
        title: 'Сумма руб.',
        key: "PAYS_SUMM",
        dataIndex: "PAYS_SUMM",
        render: (value: string, record, index) => (
            <Flex>
                <span style={{width: "100%", textAlign: 'right'}}>{`${value !== null ? Number(value.toString().replace(',', '.')).toLocaleString() : ""}`}</span>
            </Flex>
        )
    }
]