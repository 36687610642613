import {Flex} from "antd";
import React from "react";
import "./styles/styles.less"
import {Outlet} from "react-router";


export default function StatementsPage() {

    return (
        <Flex style={{width: "100%", height: "100%"}}>
            <Outlet/>
        </Flex>
    )
}