import {Button, Checkbox, Col, Divider, Flex, Form, Image, Input, notification, Row, Typography} from "antd";
import {useNavigate} from "react-router";
import {useState} from "react";
import {getRequest, postRequest} from "../../../api/httpRequest";
import dayjs from "dayjs";

export default function LoginContent() {

    const [isLogging, setIsLogging] = useState(false)
    const [api, contextHolder] = notification.useNotification()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [passwordError, setPasswordError] = useState(false)
    const [loginError, setLoginError] = useState(false)

    const esiaLogin = () => {
        getRequest("/esia/sign/auth")
            .then(response => {
                window.location.replace(response.data.url)
            })
            .catch(error => {
                console.error(error)
            })
    }

    async function digestMessage(message: string) {

        const msgUint8 = new TextEncoder().encode(message);
        const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }

    async function handleLogin(values: any) {
        dayjs().locale('ru')
        setIsLogging(true)
        let shaHashPassword = await digestMessage(values.password);
        postRequest("/esb/v1/auth/", {
            login: values.username,
            passwordHash: shaHashPassword.toUpperCase(),
            rememberMe: values.remember,
        }).then(response => {
            setIsLogging(false)
            if (response.status === 401) {
                setPasswordError(true)
            }
            navigate("/")
        }).catch(error => {
            api.error({
                message: `Ошибка авторизации`,
                description: error.response.status === 401 ? "Неправильный пароль" : "Пользователь не существует",
                duration: 3
            });
            if (error.response.status === 401) {
                setPasswordError(true)
            }
            if (error.response.status === 403) {
                setLoginError(true)
            }
            setIsLogging(false)
        })
    }

    function loginForm() {
        return <Form
            name="login"
            form={form}
            labelCol={{flex: '75px'}}
            labelAlign="left"
            initialValues={{remember: true}}
            onFinish={values => handleLogin(values)}
            autoComplete="off"
            requiredMark={false}>
            <Form.Item
                label="Логин"
                name="username"
                rules={[{required: true, message: 'Логин не может быть пустым!'}]}>
                <Input
                    status={loginError ? "error" : undefined}/>
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="password"
                rules={[{required: true, message: 'Пароль не может быть пустым!'}]}
            >
                <Input.Password
                    status={passwordError ? "error" : undefined}/>
            </Form.Item>
            <Flex gap={16} align={"center"}>
                <Row gutter={16}>
                    <Col>
                        <Form.Item>
                            <Button loading={isLogging}
                                    style={{width: "150px", height: "38.5px"}}
                                    type="primary"
                                    htmlType="submit">
                                Войти
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item wrapperCol={{flex: "200px"}} name="remember" valuePropName="checked">
                            <Checkbox>Запомнить меня</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item wrapperCol={{flex: "200px"}} name="remember" valuePropName="checked">
                            <Button type={"text"} style={{color: "blue"}} onClick={() => navigate("/login/restore")}>Забыли
                                пароль?</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Flex>
        </Form>;
    }

    return (
        <Flex className={"content content-xs"} vertical={true}>
            <Flex vertical={true}>
            {contextHolder}
            <Flex vertical gap={"16px"}>
                <div className={"lkText"}>Личный кабинет</div>
                <div className={"continueText"}>Для продолжения, пожалуйста, введите логин и пароль</div>
            </Flex>
            <Divider style={{margin: 16}}/>
            {loginForm()}
            <Flex vertical={true} gap={16}>
                <Typography.Text>Вы можете войти с помощью портала "Госуслуг" для доступа в Личный кабинет университета
                    с
                    возможностью прикрепления к поликлинике
                </Typography.Text>
                <Button style={{width: "150px", height: "38.5px", borderColor: "#6996e7"}} onClick={esiaLogin}>
                    <Image preview={false} style={{width: "100px"}}
                           src={"https://rut-miit.ru/cabinet/hello/gosuslugi-logo.png"}/>
                </Button>
            </Flex>
            </Flex>
        </Flex>
    )
}
