import {create} from "zustand";
import {IUserData} from "./IUserData";

export interface IUserState {
    user: IUserData,
    setUser: (newData: IUserData) => void
}

export const userStore = create<IUserState>()((set) => ({
    user: {teacher: false, student: false, Presentation: "", Roles: [], id_e: ""},
    setUser: (newData) => set((state) => ({user: newData})),
}))