import {Button, DatePicker, DatePickerProps, Divider, Flex, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {getRequestWithParams} from "../../../api/httpRequest";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import dayjs from "dayjs";
import CalendarMobileCard from "./CalendarMobileCard";
import {ICalendarType} from "../model/ICalendar";
import {IoMdArrowRoundBack} from "react-icons/io";

interface IMobileLesson {
    lessons: any
}

interface ICalendarMobile {
    day: string,
    lessons: IMobileLesson[]
}

export default function CalendarMobilePage() {

    const weekFormat = 'DD MMM YYYY';

    const timeConfig = [
        {time: "08:30  -  09:50"},
        {time: "10:05 - 11:25"},
        {time: "11:40 - 13:00"},
        {time: "13:45 - 15:05"},
        {time: "15:20 - 16:40"},
        {time: "16:55 - 18:15"},
        {time: "18:30 - 19:50"},
        {time: "20:00 - 21:20"},
    ]

    const dayConfig: ICalendarMobile[] = [
        {day: "Понедельник", lessons: []},
        {day: "Вторник", lessons: []},
        {day: "Среда", lessons: []},
        {day: "Четверг", lessons: []},
        {day: "Пятница", lessons: []},
        {day: "Суббота", lessons: []},
        {day: "Воскресенье", lessons: []},
    ]

    const [calendarData, setCalendarData] = useState<ICalendarMobile[]>([]);
    const [weekStep, setWeekStep] = useState(0);
    const [calendarType, setCalendarType] = useState<ICalendarType>({
        teacher: "",
        group: "",
        audirotium: "",
    });

    const [myCalendar, setMyCalendar] = useState<boolean>(true);

    const headerText = () => {
        if (calendarType.group) {
            return `Расписание по группе ${calendarType.group}`
        } else if (calendarType.audirotium) {
            return `Расписание по аудитории ${calendarType.audirotium}`
        } else if (calendarType.teacher) {
            return `Расписание преподавателя ${calendarType.teacher}`
        } else {
            return "Моё расписание"
        }
    };

    const getCalendar = (calendar: ICalendarType) => {

        let response;

        if (calendar.group) {
            response = getRequestWithParams(`/calendar/v1/group/${calendar.group}`, {
                teacher: calendar.teacher,
                weekStep: `${weekStep}`
            })
        } else if (calendar.audirotium) {
            response = getRequestWithParams(`/calendar/v1/auditorium/${calendar.audirotium}`, {
                teacher: calendar.teacher,
                weekStep: `${weekStep}`
            })
        } else {
            response = getRequestWithParams(`/calendar/v1/teacher`, {
                teacher: calendar.teacher,
                weekStep: `${weekStep}`
            })
        }

        return response
    }

    useEffect(() => {
        getCalendar(calendarType).then(response => {
            if (response.data) {
                setCalendarData(dayConfig.map((day: any, index: number) => {
                    return {
                        ...day,
                        lessons: response.data[index]
                    }
                }))
            } else {
                setCalendarData(dayConfig)
            }
        })

    }, [weekStep, calendarType]);

    const getHeaderDay = (offset: number) => {
        return dayjs()
            .add(weekStep - 1, "week")
            .endOf("week")
            .add(1, "day")
            .add(-offset, "day")
            .format("DD MMMM")
    }

    const customWeekStartEndFormat: DatePickerProps['format'] = (value) =>
        `${dayjs(value).startOf('week').format(weekFormat)} - ${dayjs(value)
            .endOf('week')
            .format(weekFormat)}`;

    const handleWeekStep = (weekOffset: number) => {
        setWeekStep(weekStep + weekOffset)
    }

    const handleCalendarTypeChange = (value: ICalendarType) => {
        setCalendarType(value)
        setMyCalendar(false)
    }

    const onWeekSelect: DatePickerProps['onChange'] = (date) => {
        if (date !== null) {
            const newWeekStep = -(dayjs().week() - dayjs(date).week())
            setWeekStep(newWeekStep)
        } else {
            setWeekStep(0)
        }
    };

    return (
        <Flex vertical={true} style={{width: "100%", height: "100%", paddingTop: 8}} gap={16}>

            <Flex vertical align={"flex-start"}>
                {!myCalendar &&
                    <Button
                        style={{margin: 0, padding: 0}}
                        onClick={() => {
                            setCalendarType({
                                teacher: "",
                                group: "",
                                audirotium: "",
                            })
                            setMyCalendar(true)
                        }}
                        icon={<IoMdArrowRoundBack/>}
                        type={"text"}>К моему расписанию
                    </Button>}
                <Typography.Text style={{fontSize: "18px"}}>{headerText()}</Typography.Text>

            </Flex>
            <Flex style={{width: "100%"}} gap={8}>
                <Button
                    onClick={() => handleWeekStep(-1)}
                    icon={<FaArrowLeft size={10}/>}
                >
                </Button>
                <Button icon={<FaArrowRight size={10}/>}
                        iconPosition={"end"}
                        onClick={() => handleWeekStep(1)}
                >
                </Button>

                <DatePicker
                    onChange={onWeekSelect}
                    format={customWeekStartEndFormat}
                    picker="week"
                    placeholder={"Выбрать"}/>
            </Flex>
            {calendarData.map((day: any, index: number) => {
                return (
                    <Flex vertical={true} gap={16}>
                        <Flex gap={8}>
                            <Typography.Text style={{fontWeight: 600}}>{day.day}</Typography.Text>
                            <Typography.Text style={{fontWeight: 100}}>{getHeaderDay(-index)}</Typography.Text>
                        </Flex>
                        {timeConfig.map((time: any, timeIndex: number) => {
                            return (
                                <CalendarMobileCard timeIndex={timeIndex} time={time} day={day.lessons}
                                                    handleCalendarTypeChange={handleCalendarTypeChange}/>
                            )
                        })}
                        <Divider style={{margin: 4}}/>
                    </Flex>
                )
            })}
        </Flex>
    )
}