import {Button, DatePicker, Flex, notification, Table, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {getRequestWithParams} from "../../../../api/httpRequest";
import {ITransfer} from "./model/transfers";
import {transfersConfig} from "./config";
import "./styles/styles.less"
import dayjs from "dayjs";
import {userStore} from "../../../../store/store";
import {RiFileExcel2Fill} from "react-icons/ri";
// @ts-ignore
import {CSVLink} from "react-csv";

export const MASKING = `${process.env.MASKING}`
export default function Transfers() {

    const [transfers, setTransfers] = useState<ITransfer[]>([]);
    const {user} = userStore()
    const [loading, setLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();

    const fetchTransfers = async (year: dayjs.Dayjs) => {
        setLoading(true);
        getRequestWithParams(
            year ? "/salary/transfer/year" : "/salary/transfer",
            year ? {tid: user.id_e, year: year.year()} : {tid: user.id_e}
        )
            .then(response => {
                var responseData;
                if (MASKING === "1") {
                    responseData = response.data.result.map((item: ITransfer) => {
                        return {
                            ...item,
                            SUMM: Math.floor((Math.random() * 1000) + 1)
                        }
                    })
                } else {
                    responseData = response.data.result
                }
                setTransfers(responseData.sort((item1: ITransfer, item2: ITransfer) => {
                    return dayjs(item1.DATST).isAfter(dayjs(item2.DATST)) ? -1 : 1
                }))
                setLoading(false);
            })
            .catch(error => {
                console.log(error)
                api.error({
                    message: 'Ошибка загрузки начислений',
                    duration: 2
                });
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchTransfers(dayjs())
    }, []);

    const headers = [
        {label: "Должность", key: "POST_CODE"},
        {label: "Подразделение", key: "INS_DEP"},
        {label: "Год", key: "EMP_CALC_YEAR"},
        {label: "Месяц", key: "EMP_CALC_MONTH"},
        {label: "Начисленно", key: "PAYS_SUMM"}
    ];

    return (
        <Flex className={"transfers"} vertical>
            {contextHolder}
            <Flex justify={"space-between"} className={"transfers-filters"}>
                <Flex gap={16} align={"center"}>
                    <Typography.Text>Год:</Typography.Text>
                    <DatePicker
                        onChange={(value) => {
                            fetchTransfers(value)
                        }}
                        picker="year"
                        defaultValue={dayjs()}
                    />
                    <Button type={"text"} icon={<RiFileExcel2Fill color={"#40a202"} size={"20"}/>}>
                        <CSVLink data={transfers} headers={headers} filename={"Расчетные листки.xlsx"}>
                            Экспорт в XLS
                        </CSVLink>
                    </Button>
                </Flex>
            </Flex>
            <Table
                style={{border: "1px #a6a6a6 solid", borderStyle: "none solid solid none"}}
                bordered
                size={"small"}
                loading={loading}
                pagination={false}
                dataSource={transfers}
                scroll={{y: 700}}
                columns={transfersConfig(transfers)}
                rowClassName={(record, index) => {
                    return index % 2 === 0 ? "transfers-darker-row" : "transfers-row"
                }}
            />
        </Flex>
    )
}