import Flex from "antd/es/flex";
import "./styles/styles.less";
import Typography from "antd/lib/typography";
import Result from "antd/lib/result";

export default function WorkInProgress () {
    return (
        <Flex className={"work-in-progress"} justify="center" align="center">
            <Result
                status="warning"
                className="text-area"
                title="Новый личный кабинет студента в разработке."
                extra={
                    <Flex vertical  justify="center" align="center">
                        <Typography.Text>Информация о готовности разделов будет отправлена на эл. почту</Typography.Text>
                    </Flex>
                }
            />
        </Flex>
    )
}