import {Button, Col, Flex, Input, InputRef, message, Row, Space, Table, TableColumnType, TableProps} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {getRequest} from "../../../api/httpRequest";
import {useNavigate} from "react-router";
import {statementsStore} from "../../../store/statement/statements";
import dayjs from "dayjs";
import {FilterDropdownProps} from "antd/es/table/interface";
import {IStatement} from "../model/IStatement";
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import StatementMobileCard from "./mobile/StatementMobileCard";

export default function StatementsList() {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {statements, setStatements} = statementsStore()
    const [messageApi, contextHolder] = message.useMessage();
    const [showCompleted, setShowCompleted] = useState(false)
    const {xl} = useBreakpoint();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    type DataIndex = keyof IStatement;
    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (
        clearFilters: () => void,
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex
    ) => {
        clearFilters();
        confirm();
        setSearchText("");
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<IStatement> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Фильтр`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Поиск
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys as string[], confirm, dataIndex)}
                        size="small"
                        style={{width: 90}}
                    >
                        Сброс
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        применить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
        ),
        onFilter: (value, record) => {
            let res = record[dataIndex]
            if (res !== undefined) {
                return res.toString().toLowerCase().includes((value as string).toLowerCase())
            }

            return false;

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const navigate = useNavigate()

    useEffect(() => {

        const fetchStatements = async () => {
            setIsLoading(true)
            try {
                const response = await getRequest("/api/v1/statements")
                setStatements(response.data.map((item: IStatement) => {
                    return {
                        ...item,
                        visible: dayjs(item.dateClose).isAfter(dayjs())
                    }
                }))
            } catch (e) {
                messageApi.open({
                    type: 'error',
                    content: 'Ошибка загрузки ведомостей',
                });
            }
            setIsLoading(false)
        }

        fetchStatements();
    }, []);

    const tableConfig: TableProps['columns'] = [
        {
            title: '№',
            dataIndex: "number",
            key: "number",
            ...getColumnSearchProps("number")
        },
        {
            title: 'Дисциплина',
            key: 'discipline',
            render: (value, record, index) => (
                record.discipline
            ),
            onCell: (data, index) => {
                return {
                    onClick: () => {
                        navigate("/statements/details", {state: {statement: data}})
                    }
                }
            },
        },
        {
            title: 'Группа',
            dataIndex: 'group',
            key: 'group',
            ...getColumnSearchProps("group")
        },
        {
            title: 'Вид контроля',
            dataIndex: 'lessonType',
            key: 'lessonType',
            ...getColumnSearchProps("lessonType")
        },

        {
            title: 'Учебный период',
            dataIndex: 'period',
            key: 'period',
            ...getColumnSearchProps("period")
        },
        {
            title: 'Учебный год',
            dataIndex: 'year',
            key: 'year',
            ...getColumnSearchProps("year")
        },
        {
            title: 'Тип ведомости',
            dataIndex: 'statementType',
            key: 'statementType',
            ...getColumnSearchProps("statementType")
        },
        {
            title: 'Статус',
            key: 'status',
            dataIndex: "status",
            ...getColumnSearchProps("status")
        },
        {
            title: 'Дата начала действия',
            dataIndex: 'dateOpen',
            key: 'dateOpen',
            render: value => {
                dayjs.locale('ru');
                const date = dayjs(value)

                return (
                    <span>{`${date.format('DD MMMM YYYY')} г.`}</span>
                )
            },
        },
        {
            title: 'Дата окончания действия',
            dataIndex: 'dateClose',
            key: 'dateClose',
            render: value => {
                dayjs.locale('ru');
                const date = dayjs(value)

                return (
                    <span>{`${date.format('DD MMMM YYYY')} г.`}</span>
                )
            },
        },
        {
            title: '',
            key: 'statementId',
            render: record => (

                <Button
                    style={{fontSize: "12px"}} onClick={() => {
                    navigate("/statements/details", {state: {statement: record}})
                }}>
                    Открыть
                </Button>
            ),
        },
    ]


    const onFilterClick = () => {
        console.log(JSON.stringify(statements))
        setStatements(statements.map(item => (
                {
                    ...item,
                    visible: !showCompleted ? true : dayjs(item.dateClose).isAfter(dayjs())
                }
            ))
        )
        setShowCompleted(!showCompleted)
        console.log(JSON.stringify(statements))
    }

    return (
        <Flex className={"listContent"} justify={"flex-start"} align={"flex-start"} vertical={true}>
            {contextHolder}
            <Button style={xl ? {width: "300px"} : {width: "100%"}}
                    onClick={onFilterClick}>{!showCompleted ? "Показать закрытые" : "Скрыть закрытые"}</Button>
            {contextHolder}
            {xl ?
                <Table
                    loading={isLoading}
                    className={"statementsTable"}
                    bordered
                    pagination={false}
                    columns={tableConfig}
                    dataSource={statements.filter(statement => statement.visible)}/>
                :
                <Row style={{paddingTop: "8px"}} gutter={[0, 8]}>
                    {statements.map(item => {
                        return (
                            <Col>
                            <StatementMobileCard statement={item}/>
                            </Col>
                        )
                    })}
                </Row>

            }

        </Flex>
    )
}