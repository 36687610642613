import {Flex} from "antd";
import "./styles/styles.less"
import React from "react";
import CalendarWebPage from "./web/CalendarWebPage";
import CalendarMobilePage from "./mobile/CalendarMobilePage";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export default function CalendarPage() {

    const {xs, sm, xl} = useBreakpoint();

    return (
        <Flex vertical={true} style={{width: "100%", height: "100%"}} gap={16}>
            {!xl ? <CalendarMobilePage/> : <CalendarWebPage/>}
        </Flex>
    )
}