import {Button, Flex, Table} from "antd";
import React, {useEffect, useState} from "react";
import {headersTableConfig, tableConfig} from "./config";
import {ISalary, ISalaryHeader} from "../../model/ISalary";
import {getRequestWithParams} from "../../../../api/httpRequest";
import {userStore} from "../../../../store/store";
import Typography from "antd/lib/typography";
import dayjs from "dayjs";
// @ts-ignore
import {CSVLink} from 'react-csv'
import {RiFileExcel2Fill} from "react-icons/ri";


const importantRows = ["Начислено", "Всего начислено", "Удержано", "Всего удержано", "К выдаче"]
export const MASKING = `${process.env.MASKING}`

export default function Accruals() {
    const [salaryHeaders, setSalaryHeaders] = useState<ISalaryHeader[]>([])
    const {user} = userStore()
    const [isLoading, setIsLoading] = useState(true)
    const [isDetailsLoading, setIsDetailsLoading] = useState(true)
    const [selectedRow, setSelectedRow] = useState<number | undefined>(0)
    const [selectedAccruals, setSelectedAccruals] = useState<ISalary>()

    const fetchAccrualDetails = (header: ISalaryHeader) => {
        setIsDetailsLoading(true)
        getRequestWithParams(
            "/salary/payroll/details/date",
            {
                pref: header.PREF,
                numb: header.NUMB,
                date: header.EMP_CALC
            })
            .then(result => {
                if (MASKING === "1") {
                    setSelectedAccruals({
                        ...result.data.result[0],
                        data: result.data.result[0].data.map((item: ISalary) => {
                            return {
                                ...item,
                                PAYS_SUMM: Math.floor((Math.random() * 1000) + 1)
                            }
                        })
                    })
                } else {
                    setSelectedAccruals(result.data.result[0])
                }

                setIsDetailsLoading(false)
            })
    }

    useEffect(() => {
        const fetchSalaryHeaders = () => {
            setIsLoading(true)
            getRequestWithParams("/salary/payroll/headers", {tid: user.id_e})
                .then(result => {

                    const salaryHeadersResponse = result.data.result.sort((item1: ISalaryHeader, item2: ISalaryHeader) => {
                        return dayjs(item1.EMP_CALC).isAfter(dayjs(item2.EMP_CALC)) ? -1 : 1
                    })

                    if (MASKING === "1") {
                        setSalaryHeaders(salaryHeadersResponse.map((item: ISalaryHeader) => {
                            return {
                                ...item,
                                PAYS_SUMM: Math.floor((Math.random() * 100) + 1)
                            }
                        }))
                    } else {
                        setSalaryHeaders(salaryHeadersResponse)
                    }
                    fetchAccrualDetails(salaryHeadersResponse[0])
                    setIsLoading(false)
                })
        }

        fetchSalaryHeaders()

    }, []);

    const headers = [
        {label: "Должность", key: "POST_CODE"},
        {label: "Подразделение", key: "INS_DEP"},
        {label: "Год", key: "EMP_CALC_YEAR"},
        {label: "Месяц", key: "EMP_CALC_MONTH"},
        {label: "Начислено", key: "PAYS_SUMM"}
    ];

    return (
        <Flex vertical={true} gap={8}>
            <Typography.Text>Для просмотра доступна информация о выплатах в кассе, произведённых после
                04.04.2013
                г.</Typography.Text>
            <Flex justify={"space-between"} align={"center"}>
                <Typography.Text className={"title-text"}>Расчетные листки</Typography.Text>
                <Button type={"text"} icon={<RiFileExcel2Fill color={"#40a202"} size={"20"}/>}>
                    <CSVLink data={salaryHeaders.map(item => {
                        return {
                            ...item,
                            EMP_CALC_YEAR: dayjs(item.EMP_CALC).year().toString(),
                            EMP_CALC_MONTH: dayjs(item.EMP_CALC).format("MMMM").toString(),
                        }
                    })} headers={headers} filename={"Расчетные листки.xlsx"}>
                        Экспорт в XLS
                    </CSVLink>
                </Button>
            </Flex>
            <Table
                style={{border: "1px #a6a6a6 solid", borderStyle: "none solid solid none"}}
                bordered
                loading={isLoading}
                size={"small"}
                pagination={false}
                dataSource={salaryHeaders}
                scroll={{y: 200}}
                columns={headersTableConfig(salaryHeaders)}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            setSelectedRow(rowIndex)
                            fetchAccrualDetails(record)
                        },
                    };
                }}
                rowClassName={(record, index) => {
                    if (index === selectedRow) {
                        return "selected-row"
                    }
                    return index % 2 === 0 ? "" : "darker-row"
                }}
            />
            <Typography.Text
                className={"title-text"}>{`Расчеты за ${dayjs(selectedAccruals?.EMP_CALC).format("MMMM")} ${dayjs(selectedAccruals?.EMP_CALC).year()} года`}</Typography.Text>
            <Table
                style={{border: "1px #a6a6a6 solid", borderStyle: "none solid solid none"}}
                bordered
                size={"small"}
                pagination={false}
                loading={isDetailsLoading}
                columns={tableConfig}
                scroll={{y: 450}}
                dataSource={selectedAccruals?.data}
                rowClassName={(record, index) => {
                    return importantRows.indexOf(record.PAYS_NAME) > -1 ? "important-row" : ""
                }}
            />
        </Flex>);
}