import {AxiosResponse} from "axios";
import axiosInstance from "./axiosInstance";

export const getRequest = (url: string): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(`${url}`)
}

export const getRequestWithParams = (url: string, params: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(`${url}`, {params: params})
}

export const downloadFileWithParams = (url: string, params: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(`${url}`, {params: params, responseType: "blob"})
}
export const postRequest = (url: string, body: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(`${url}`, body)
}

export const putRequest = (url: string, body: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`${url}`, body)
}

export const putRequestWithParams = (url: string, params: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`${url}`, null, {params: params})
}

export const putRequestWithParamsAndBody = (url: string, params: any, body: any): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`${url}`, body, {params: params})
}