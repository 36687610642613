import {Flex, Typography} from "antd";
import "./styles/styles.less"
import {Outlet} from "react-router";
import 'dayjs/locale/ru';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";


const {Text} = Typography;

export default function LoginPage() {

    const {xl} = useBreakpoint()

    return (
        <Flex className={"login"} justify={"space-between"} align={"center"} vertical>
            <Flex className={"loginTopTextBox"} vertical justify={"center"}>
                <Text className={xl ? "loginTopText" : "loginTopText-xs"}>Российский университет транспорта (МИИТ)</Text>
            </Flex>
            <Outlet/>
            <Flex vertical className={"loginFooterTextBox"} justify={"flex-end"}>
                <Text
                    className={xl ? "loginFooterText" : "loginFooterText-xs"}>{`© ${new Date().getFullYear()} «Российский университет транспорта (МИИТ)»`}</Text>
            </Flex>
        </Flex>
    )
}
