import {App, Button, Divider, Flex, Form, Input, notification, Tag, Typography} from "antd";
import {postRequest} from "../../../api/httpRequest";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router";

export default function PasswordResetContent() {


    const [form] = Form.useForm();
    const [isLogging, setIsLogging] = useState(false)
    const {notification} = App.useApp();
    const navigate = useNavigate()
    const location = useLocation()


    async function handleReset(values: any) {
        setIsLogging(true)
        const response = await postRequest("/esb/v1/user/resetPass", {
            id: location.search.substring(4, location.search.length),
            password: values.password
        })

        if (response.status !== 200) {
            notification.error({
                message: `${JSON.stringify(response)}`,
                duration: 3
            });
            setIsLogging(false)
            return
        }

        notification.success({
            message: `Пароль успешно изменен`,
            duration: 3
        });
        setIsLogging(false)
        navigate("/login")
    }


    const checkEquals = (_: any, value: { passwordCheck: string }) => {
        if (form.getFieldValue("password") !== value) {
            return Promise.reject(new Error('Пароли не совпадают'))
        }
        return Promise.resolve()
    }

    const resetForm = () => {
        return <Form
            name="reset"
            form={form}
            labelCol={{flex: '120px'}}
            labelAlign="left"
            initialValues={{remember: true}}
            onFinish={values => handleReset(values)}
            requiredMark={false}>
            <Form.Item
                label="Новый пароль"
                name="password"
                hasFeedback
                rules={[{
                    required: true,
                    message: 'Пароль не соответствует требованиям',
                    pattern: new RegExp("^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\\-__+.]){1,}).{6,}$")
                }]}>
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="Пароль еще раз"
                name="passwordCheck"
                hasFeedback
                rules={[{required: true, message: 'Пароли не совпадают', validator: checkEquals}]}>
                <Input.Password/>
            </Form.Item>
            <Form.Item
                style={{padding: 0, margin: 0}}>
                <Button loading={isLogging} style={{width: "150px", height: "38.5px"}} type="primary"
                        htmlType="submit">
                    Подтвердить
                </Button>
            </Form.Item>
        </Form>;
    };

    return (
        <Flex className={"content"} vertical={true}>
            <Flex vertical gap={"16px"}>
                <Typography.Text className={"lkText"}>
                    Востановление пароля
                </Typography.Text>
                <Typography.Text className={"continueText"}>
                    Для продолжения, введите новый пароль.
                </Typography.Text>
                <Tag style={{width: "100%", whiteSpace: "pre-wrap"}} color={"processing"}>
                    Пароль должен быть не менее 6 символов, содержать латинские прописные и заглавные буквы,
                    минимум одну цифру, а так же минимум один спецсимвол
                </Tag>
            </Flex>
            <Divider style={{margin: 16}}/>
            {resetForm()}
        </Flex>
    )
}